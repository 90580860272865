/* eslint-disable */
import {Vue, Options} from 'vue-class-component';
import { Getter, Mutation, State, Action} from 'vuex-class'
import { HisOwnDirector } from '@/store/hisOwnDirector/types'
import HisOwnDirectorModelChoicePage from '@/components/pages/his-own-director/model-choice/his-own-director-model-choice.page';

const namespace: string = 'hisOwnDirector';

@Options({
	name: 'HisOwnDirectorRequestPage',
	components: {
		HisOwnDirectorModelChoicePage
	}
})
export default class HisOwnDirectorRequestPage extends Vue {
	@State('hisOwnDirector') hisOwnDirector: HisOwnDirector | undefined;
	@Action('getAllModels', {namespace}) getAllModels: any;
	@Action('storeRequestHisOnDirector', {namespace}) storeRequestHisOnDirector: any;
	@Mutation('setNumOfModels', {namespace}) setNumOfModels: any;
	@Mutation('setSelectedModelLength', {namespace}) setSelectedModelLength: any;
	@Getter('getNumOfModels', {namespace}) getNumOfModels: any;

	numberOfModels: number = 0;
	timeVideo: number = 0;
	scenario: string = '';
	username: string = '';
	email: string = '';
	phone: string = '';

	clickNumOfModels(operator: string) {
		this.numberOfModels = this.getNumOfModels;
		this.setSelectedModelLength(this.numberOfModels);
		if(operator === 'minus') {
			this.numberOfModels > 0 ? this.numberOfModels-- : this.numberOfModels = 0;
			this.setNumOfModels(this.numberOfModels);
			this.setSelectedModelLength(this.numberOfModels);

		}
		if(operator === 'plus') {
			this.numberOfModels++;
			this.setNumOfModels(this.numberOfModels);
			this.setSelectedModelLength(this.numberOfModels);
		}
	}

	onSubmit() {
		this.storeRequestHisOnDirector({
			timeVideo: this.timeVideo,
			scenario: this.scenario,
			username: this.username,
			email: this.email,
			phone: this.phone
		})
		this.timeVideo = 0;
		this.scenario = '';
		this.username = '';
		this.email = '';
		this.phone = '';
		// this.$router.push({ name: 'his-own-director-video-greetings-request' }).then(r => r);
	}
}
